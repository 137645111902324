<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :secondary-action="[]"
      :breadcrumb="breadcrumbTitle"
      v-on:breadcrumb="breadcrumbAction"
      :title="pageTitle"></subpage-header>
    <div class="subpage-information-area-margin" v-if="localClient">
      <div class="box">
        <b-field class="is-bordered">
          <b-radio-button v-model="localClient.type" native-value="business" type="is-primary">
            <b-icon icon="factory"></b-icon>
            <span>Business</span>
          </b-radio-button>
          <b-radio-button v-model="localClient.type" native-value="individual" type="is-primary">
            <b-icon icon="emoticon-happy"></b-icon>
            <span>Individual</span>
          </b-radio-button>
        </b-field>
        <hr>
        <b-field label="Client Name" :message="(errors.name) ? (errors.name[0]) : null" :type="(errors.name) ? 'is-danger' : null">
          <b-input v-model="localClient.name"></b-input>
        </b-field>
        <b-field v-if="isBusiness" label="Primary Contact Person">
          <b-input v-model="localClient.contact_person"></b-input>
        </b-field>
        <b-field label="Phone">
          <b-input v-model="localClient.phone" :message="(errors.phone) ? errors.phone[0] : null" :type="(errors.phone) ? 'is-danger' : null"></b-input>
        </b-field>
        <b-field label="Email" :message="(errors.email) ? (errors.email[0]) : null" :type="(errors.email) ? 'is-danger' : null">
          <b-input v-model="localClient.email"></b-input>
        </b-field>
        <div v-if="isBusiness">
          <a class="header-link field" v-on:click="addAdditionalContact">
            <b-button class="payment-rounded-button" icon-left="plus" rounded>Add Contact</b-button>
          </a>
          <p class="subtitle" v-if="additionalContacts >= 1">Additional Contacts</p>
          <div class="box contact-box" v-for="(contact, index) in additionalContacts" :key="contact.id">
            <div class="columns">
              <div class="column is-3">
                <b-field label="Name" class="modal-label" expanded>
                  <b-input v-model="contact.name" value="" type="text" placeholder="Name"/>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="Email"  expanded class="modal-label">
                  <b-input v-model="contact.email" value="" type="text" placeholder="address@example.com"/>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Phone"  expanded class="modal-label">
                  <b-input v-model="contact.phone" type="text" placeholder="0404 123 456"/>
                </b-field>
              </div>
              <div class="column is-1">
                <button class="button delete-button is-dark has-icon" @click="removeAdditionalContact(index)">
                  <icon icon="bin" stroke="#FFFFFF" :width="20" :height="20"/>
                </button>
              </div>
            </div>
          </div>
          <br>
        </div>
        <b-field v-if="isBusiness"  label="ABN">
          <b-input v-model="localClient.business_registration_number"></b-input>
        </b-field>
        <b-field label="Address">
          <b-input maxlength="200" type="textarea" v-model="localClient.address"></b-input>
        </b-field>
        <b-field label="Website">
          <b-input v-model="localClient.website"></b-input>
        </b-field>
        <b-field label="Notes">
          <b-input maxlength="200" type="textarea" v-model="localClient.notes"></b-input>
        </b-field>
        <b-field label="Billing Currency" v-if="useMultipleCurrencies">
          <b-select placeholder="Select a currency" v-model="localClient.default_currency_id">
            <option :value="0">-- Use My Primary Currency -- </option>
            <option
              v-for="currency in currencies"
              :value="currency.id"
              :key="currency.id">
              {{ currency.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Preferred Language">
          <b-select placeholder="Select a language" v-model="localClient.default_language_id">
            <option :value="0">-- Use My Primary Language -- </option>
            <option
              v-for="language in languages"
              :value="language.id"
              :key="language.id">
              {{ language.name }}
            </option>
          </b-select>
        </b-field>
        <button v-on:click="save" class="button is-primary">Save</button>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .delete-button {
    margin-top: 22px;
  }
  .field {
    margin-bottom: 20pt;
  }
  .contact-box {
    background-color: #E0E0E0;
  }
</style>

<script>
import SubpageHeader from '@/components/SubpageHeader'
import Icon from '@/components/Icons/Icon'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([

    ]),
    pageTitle () {
      if (this.id) {
        return 'Edit Client'
      }
      return 'Create Client'
    },
    account () {
      return this.$store.getters['account/account']
    },
    client () {
      return this.$store.getters['clients/client']
    },
    currencies () {
      return this.$store.getters['currencies/currencies']
    },
    languages () {
      return this.$store.getters['languages/languages']
    },
    useMultipleCurrencies () {
      return this.$store.getters['account/useMultipleCurrencies']
    },
    breadcrumbTitle () {
      if (this.id) {
        return 'Back To Client'
      }
      return 'Clients'
    },
    isBusiness () {
      return this.localClient && this.localClient.type === 'business'
    },
    isIndividual () {
      return this.localClient && this.localClient.type === 'individual'
    },
    additionalContacts () {
      return this.localClient.additional_contacts.filter((contact) => {
        return contact.deleted !== true
      })
    }
  },
  data () {
    return {
      localClient: null,
      activeTab: null,
      isLoading: false,
      errors: {
        name: null
      }
    }
  },
  mounted () {
    this.$store.dispatch('account/fetch')
    this.$store.dispatch('currencies/fetch')
    this.$store.dispatch('languages/fetch')
    if (this.id) {
      this.$store.dispatch('clients/fetch', this.id)
        .then((response) => {
          this.localClient = response.data
        })
    } else {
      this.$store.dispatch('clients/resetClient')
      this.localClient = {
        type: 'individual',
        name: '',
        additional_contacts: [],
        phone: '',
        email: '',
        notes: ''
      }
    }
  },
  methods: {
    addAdditionalContact () {
      this.localClient.additional_contacts.unshift({ id: null, name: '', email: '', phone: '', deleted: false })
    },
    removeAdditionalContact (additionalContactIndex) {
      this.additionalContacts[additionalContactIndex].deleted = true
    },
    breadcrumbAction () {
      if (this.client && this.client.id) {
        this.$router.push({ name: 'clients-show', id: this.client.id })
      } else {
        this.$router.push({ name: 'clients' })
      }
    },
    save () {
      this.$store.dispatch('clients/save', this.localClient)
        .then((response) => {
          this.$router.push({ name: 'clients-show', params: { id: response.data.id } })
        })
        .catch((e) => {
          const errors = e.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    },
    fetch () {
      this.$store.dispatch('clients/fetch', this.id)
    }
  },
  name: 'ClientEditor',
  components: { SubpageHeader, Icon },
  props: ['id']
}
</script>

<style>
  .tabs.is-toggle li.is-active a {
    background-color: #1BCA8E;
    border-color: #203752;
    color: white;
    z-index: 1;
  }
</style>
